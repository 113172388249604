import React, { useState, useEffect } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';

const TeamPlayMatchForm = (props) => {
  const initialTeams = [];
  var [teamList, setTeamList] = useState([]);
  var [teams, setTeams] = useState(initialTeams);
  useEffect(() => {
    if (props.currentId == '') {
      setTeams(initialTeams);
    } else {
      setTeams(props.matchObjects[props.currentId].teams);
    }
  }, [props.currentId, props.matchObjects]);
  useEffect(() => {
    if (props.teamObjects !== null) {
      let list = [];
      Object.keys(props.teamObjects).map((id) => {
        list.push({
          id,
          teamName: props.teamObjects[id].teamName,
        });
      });
      setTeamList(list);
    } else {
      setTeamList([]);
    }
  }, [props.teamObjects]);
  const handleSelectChange = (selectedPlayer) => {
    setTeams(selectedPlayer);
  };
  const onRemoveSelectedValue = (remainingPlayers) => {
    setTeams(remainingPlayers);
  };
  const handleFormSubmit = (e) => {
    let values = {
      teams,
    };
    e.preventDefault();
    props.addOrEdit(values);
  };
  return (
    <form autoComplete="off" onSubmit={handleFormSubmit}>
      <div className="mb-3">
        <label>Select teams</label>
        <div className="form-floating">
          <Multiselect
            showCheckbox={true}
            selectionLimit={4}
            options={teamList} // Options to display in the dropdown
            selectedValues={teams} // Preselected value to persist in dropdown
            onSelect={handleSelectChange} // Function will trigger on select event
            onRemove={onRemoveSelectedValue} // Function will trigger on remove event
            displayValue="teamName" // Property name to display in the dropdown options
            placeholder={'Start typing to search...'}
            closeOnSelect={false}
          />
        </div>
      </div>
      <input
        value={props.currentId ? 'Update' : 'Save'}
        type="submit"
        className="btn btn-primary btn-block"
      />
    </form>
  );
};

export default TeamPlayMatchForm;
