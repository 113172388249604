import React, { useState, useEffect } from 'react';
import Home from './Home';
import _, { map } from 'lodash';
import firebaseDb from '../firebase';
import { Link } from 'react-router-dom';
import TeamPlayMatchBoardForm from './TeamPlayMatchBoardForm';
const TeamPlayMatchBoard = () => {
  var [teamObjects, setTeamObjects] = useState({});
  var [matchObjects, setMatchObject] = useState({});
  var [scoreCardObjects, setScoreCardObject] = useState({});
  var [matchId, setMatchId] = useState();
  var [teamId, setTeamId] = useState();
  var [playerId, setPlayerId] = useState();
  var [betValues, setBetValues] = useState({});
  var [betObjects, setBetObject] = useState({});
  useEffect(() => {
    firebaseDb.child('teams').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        setTeamObjects({ ...snapshot.val() });
      } else {
        setTeamObjects({});
      }
    });
    firebaseDb.child('teamPlayMatches').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        setMatchObject({ ...snapshot.val() });
      } else {
        setMatchObject({});
      }
    });
    firebaseDb.child('teamPlayScorecard').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        setScoreCardObject({ ...snapshot.val() });
      } else {
        setScoreCardObject({});
      }
    });
    firebaseDb.child('teamPlayBetMoney').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        setBetObject({ ...snapshot.val() });
      } else {
        setBetObject({});
      }
    });
  }, []);
  useEffect(() => {
    if (Object.keys(matchObjects) && Object.keys(matchObjects)[0]) {
      setMatchId(Object.keys(matchObjects)[0]);
      setTeamId(matchObjects[Object.keys(matchObjects)[0]].teams[0]);
      setPlayerId(
        teamObjects[matchObjects[Object.keys(matchObjects)[0]].teams[0].id]
          .players[0],
      );
    }
  }, [matchObjects]);
  useEffect(() => {
    findWinner();
  }, [scoreCardObjects]);
  useEffect(() => {
    let showMatchId = Object.keys(matchObjects)[0];
    let betObj = _.pickBy(betObjects, (obj) =>
      _.isEqual(obj.matchId, showMatchId),
    );
    let bets = _.values(betObj);
    let obj = {};
    _.map(bets, (bet) => {
      obj[`${bet.teamId}`] = bet.money;
    });
    setBetValues(obj);
  }, [betObjects]);
  const updatePlayerOnCard = (matchId, teamId, playerId) => {
    setMatchId(matchId);
    setTeamId(teamId);
    setPlayerId(playerId);
  };
  const findHighestIndividualScore = (matchId, teamId, playerId) => {
    const scoreCards = _.filter(scoreCardObjects, {
      matchId,
      teamId,
      playerId,
    });
    if (_.isEmpty(scoreCards)) {
      return 0;
    }
    let maxScore = _.get(scoreCards[0], 'score');
    if (_.isEmpty(maxScore)) {
      return 0;
    }
    if (_.isArray(maxScore)) {
      maxScore = _.map(maxScore, (mScore) => {
        if (_.isNaN(_.parseInt(mScore))) {
          return 0;
        } else {
          return _.parseInt(mScore);
        }
      });
      return _.parseInt(_.max(maxScore));
    } else {
      maxScore = _.map(_.values(maxScore), (mScore) => {
        if (_.isNaN(_.parseInt(mScore))) {
          return 0;
        } else {
          return _.parseInt(mScore);
        }
      });
      return _.parseInt(_.max(maxScore));
    }
  };
  const calculateTeamTotal = (matchId, teamId) => {
    let players = teamObjects[teamId].players;
    let teamTotal = 0;
    _.map(players, (player) => {
      let maxScore = findHighestIndividualScore(matchId, teamId, player.id);
      teamTotal = teamTotal + maxScore;
    });
    return teamTotal;
  };
  const findWinner = () => {
    if (!_.isEmpty(scoreCardObjects) && matchObjects) {
      let scoreCardCount = 0;
      let showMatchId = Object.keys(matchObjects)[0];
      const scoreCards = _.filter(scoreCardObjects, {
        matchId: showMatchId,
      });
      if (matchObjects && matchObjects[showMatchId]) {
        _.map(matchObjects[Object.keys(matchObjects)].teams, (team) => {
          scoreCardCount =
            scoreCardCount + _.size(teamObjects[team.id].players);
        });
      }
      if (_.size(scoreCards) != scoreCardCount) {
        return false;
      }
      for (let i = 0; i < scoreCards.length; i++) {
        if (_.size(scoreCards[i].score) != 6) {
          return false;
        }
      }
      let teamMaxScores = [];
      _.map(
        matchObjects &&
          matchObjects[Object.keys(matchObjects)] &&
          matchObjects[Object.keys(matchObjects)].teams,
        (team) => {
          teamMaxScores.push({
            teamId: team.id,
            maxScore: calculateTeamTotal(showMatchId, team.id),
          });
        },
      );
      let maxTeam =
        _.maxBy(teamMaxScores, 'maxScore') &&
        _.maxBy(teamMaxScores, 'maxScore').teamId;
      if (maxTeam) {
        firebaseDb
          .child(`teamPlayMatches/${Object.keys(matchObjects)[0]}`)
          .set(
            { ...matchObjects[Object.keys(matchObjects)[0]], winner: maxTeam },
            (err) => {
              if (err) {
                console.log(err);
              }
            },
          );
      }
    }
  };
  const handleInputChange = (teamId, e) => {
    const { value } = e.target;
    setBetValues({
      ...betValues,
      [teamId]: value,
    });
  };
  const handleFormSubmit = (teamId, e) => {
    e.preventDefault();
    let showMatchId = Object.keys(matchObjects)[0];
    console.log(betValues);
    let currentBetMoney = betValues[teamId] || 0;
    let obj = {};
    let betObj = _.pickBy(
      betObjects,
      (obj) =>
        _.isEqual(obj.matchId, showMatchId) && _.isEqual(obj.teamId, teamId),
    );
    let betObjKey = _.head(_.keys(betObj));
    let betObjValues = _.head(_.values(betObj));
    if (_.size(betObjValues)) {
      obj = { ...betObjValues, money: currentBetMoney };
      firebaseDb.child(`teamPlayBetMoney/${betObjKey}`).set(obj, (err) => {
        if (err) {
          console.log(err);
        }
      });
    } else {
      obj = { matchId: showMatchId, teamId: teamId, money: currentBetMoney };
      firebaseDb.child('teamPlayBetMoney').push(obj, (err) => {
        if (err) {
          console.log(err);
        }
      });
    }
  };
  let showMatchId = Object.keys(matchObjects)[0];
  let showTeams = [],
    showTeamsUpdated;
  if (showMatchId) {
    showTeams = _.map(matchObjects[showMatchId].teams, (team) => {
      return { ...team, total: calculateTeamTotal(showMatchId, team.id) };
    });
    showTeamsUpdated = _.reverse(_.sortBy(showTeams, 'total'));
  }
  return (
    <Home heading={'Team Play'}>
      <div className="team-match-board">
        {Object.keys(matchObjects).length < 1 && <div>No matches</div>}
        {Object.keys(matchObjects).length > 0 &&
          matchId &&
          teamId &&
          playerId && (
            <TeamPlayMatchBoardForm
              matchId={matchId}
              teamId={teamId}
              playerId={playerId}
            />
          )}
        <div className="team-match">
          {_.map(showTeamsUpdated, (team, index) => {
            return (
              <div key={team.id} className="team">
                <div className="team-name">{teamObjects[team.id].teamName}</div>
                <div className="team-players">
                  {_.map(teamObjects[team.id].players, (player) => {
                    return (
                      <div
                        key={player.id}
                        className="players"
                        onClick={() => {
                          updatePlayerOnCard(
                            showMatchId,
                            { id: team.id, teamName: team.teamName },
                            { id: player.id, fullName: player.fullName },
                          );
                        }}
                      >
                        <div className="players-name">
                          {_.split(player.fullName, ' ', 2)[1]
                            ? _.split(player.fullName, ' ', 2)[1]
                            : _.split(player.fullName, ' ', 2)[0]}
                        </div>
                        <div className="players-score">
                          {findHighestIndividualScore(
                            showMatchId,
                            team.id,
                            player.id,
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div
                  className="team-total"
                  className={
                    matchObjects &&
                    matchObjects[showMatchId] &&
                    matchObjects[showMatchId].winner &&
                    matchObjects[showMatchId].winner === team.id
                      ? 'team-total winner'
                      : 'team-total'
                  }
                >
                  <div className="total">Total</div>
                  <div className="score">
                    {calculateTeamTotal(showMatchId, team.id)}
                  </div>
                </div>
                <div className="team-money">
                  <div className="team-money-label">Bet money</div>
                  <form
                    autoComplete="off"
                    onSubmit={(e) => handleFormSubmit(team.id, e)}
                  >
                    <input
                      className="bet-input"
                      placeholder=""
                      name={index}
                      value={betValues && betValues[team.id]}
                      onChange={(e) => {
                        handleInputChange(team.id, e);
                      }}
                      onBlur={(e) => handleFormSubmit(team.id, e)}
                    />
                  </form>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Home>
  );
};

export default TeamPlayMatchBoard;
