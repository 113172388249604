import React, { useState, useEffect } from 'react';

const MatchForm = (props) => {
  const initialFieldValues = {
    player1: '',
    player2: '',
    matchType: '',
  };
  var [values, setValues] = useState(initialFieldValues);
  var [playerList, setPlayerList] = useState([]);
  useEffect(() => {
    if (props.currentId == '') {
      setValues({ ...initialFieldValues });
    } else {
      setValues({ ...props.matchObjects[props.currentId] });
    }
    if (props.playerObjects !== null) {
      let list = [];
      Object.keys(props.playerObjects).map((id) => {
        list.push({
          id,
          fullName: props.playerObjects[id].fullName,
        });
      });
      setPlayerList(list);
    } else {
      setPlayerList([]);
    }
  }, [props.currentId, props.matchObjects]);
  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSelectChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    props.addOrEdit(values);
  };

  return (
    <form autoComplete="off" onSubmit={handleFormSubmit}>
      <div className="mb-3">
        <div className="form-floating">
          <select
            className="form-select"
            id="floatingSelect"
            name="player1"
            value={values.player1}
            onChange={handleSelectChange}
          >
            <option>Select</option>
            {playerList &&
              playerList.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.fullName}
                  </option>
                );
              })}
          </select>
          <label>Player 1</label>
        </div>
      </div>

      <div className="mb-3">
        <div className="form-floating">
          <select
            className="form-select"
            id="floatingSelect"
            name="player2"
            value={values.player2}
            onChange={handleSelectChange}
          >
            <option>Select</option>
            {playerList &&
              playerList.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.fullName}
                  </option>
                );
              })}
          </select>
          <label>Player 2</label>
        </div>
      </div>

      <div className="mb-3">
        <div className="form-floating">
          <select
            className="form-select"
            id="floatingSelect"
            name="matchType"
            value={values.matchType}
            onChange={handleSelectChange}
          >
            <option>Select</option>
            {props.matchTypes &&
              props.matchTypes.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                );
              })}
          </select>
          <label>Match Type</label>
        </div>
      </div>
      <input
        value={props.currentId ? 'Update' : 'Save'}
        type="submit"
        className="btn btn-primary btn-block"
      />
    </form>
  );
};

export default MatchForm;
