import React, { useState, useEffect } from 'react';

const PlayerForm = (props) => {
  const initialFieldValues = {
    fullName: '',
  };
  var [values, setValues] = useState(initialFieldValues);
  useEffect(() => {
    if (props.currentId == '') {
      setValues({ ...initialFieldValues });
    } else {
      setValues({ ...props.playerObjects[props.currentId] });
    }
  }, [props.currentId, props.playerObjects]);
  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    props.addOrEdit(values);
  };
  return (
    <form autoComplete="off" onSubmit={handleFormSubmit}>
      <div className="mb-3">
        <input
          className="form-control"
          placeholder="Full Name"
          name="fullName"
          value={values.fullName}
          onChange={handleInputChange}
        />
      </div>
      <div className="mb-3">
        <input
          value={props.currentId ? 'Update' : 'Save'}
          type="submit"
          className="btn btn-primary btn-block"
        />
      </div>
    </form>
  );
};

export default PlayerForm;
