import React from 'react';
import './App.css';
import './styles/index.scss';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Players from './components/Players';
import Matches from './components/Matches';
import MatchBoard from './components/MatchBoard';
import MatchBoardForm from './components/MatchBoardForm';
import Teams from './components/Teams';
import TeamPlayMatches from './components/TeamPlayMatches';
import TeamPlayMatchBoard from './components/TeamPlayMatchBoard';

function App() {
  return (
    <div className="container">
      <div className="row">
        <Router>
          <Switch>
            <Route path="/players">
              <Players />
            </Route>
            <Route path="/matches">
              <Matches />
            </Route>
            <Route path="/match-board">
              <MatchBoard />
            </Route>
            <Route path="/" exact>
              <MatchBoard />
            </Route>
            <Route path="/scorecard/:matchId/:playerId" exact>
              <MatchBoardForm />
            </Route>
            <Route path="/teams" exact>
              <Teams />
            </Route>
            <Route path="/team-play-matches" exact>
              <TeamPlayMatches />
            </Route>
            <Route path="/team-play" exact>
              <TeamPlayMatchBoard />
            </Route>
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
