import firebase from 'firebase';

//live test 4th Aug 2021

//progton config
const firebaseConfig = {
  apiKey: "AIzaSyBmjzQXXZg5XI0Dg_8A9de0TDJG84SnQIk",
  authDomain: "winningticket-7b4c4.firebaseapp.com",
  projectId: "winningticket-7b4c4",
  storageBucket: "winningticket-7b4c4.appspot.com",
  messagingSenderId: "949331008523",
  appId: "1:949331008523:web:7cf06264070499df65fb0e"
};


//var firebaseConfig = {
//  apiKey: 'AIzaSyAUeW36_In8PWhGl2ZArzGRr8I24-RU72w',
//  authDomain: 'adios-production-test.firebaseapp.com',
//  projectId: 'adios-production-test',
//  storageBucket: 'adios-production-test.appspot.com',
 // messagingSenderId: '741037994515',
//  appId: '1:741037994515:web:16688ea57b6f038c27c5e6',
//};

// //production aws

// var firebaseConfig = {
//   apiKey: 'AIzaSyBHFdj1_HZ2MaMUz8coJx9C5W6fx2fDFCc',
//   authDomain: 'adios-production.firebaseapp.com',
//   databaseURL: 'https://adios-production-default-rtdb.firebaseio.com',
//   projectId: 'adios-production',
//   storageBucket: 'adios-production.appspot.com',
//   messagingSenderId: '112422081382',
//   appId: '1:112422081382:web:d323053b0194c479692ea7',
// };
//live heroku
// var firebaseConfig = {
//   apiKey: 'AIzaSyAnfpEHJRCnCA8TypC16aLZaljVvbJuf8M',
//   authDomain: 'adios-rsl.firebaseapp.com',
//   projectId: 'adios-rsl',
//   storageBucket: 'adios-rsl.appspot.com',
//   messagingSenderId: '244296561052',
//   appId: '1:244296561052:web:7d431de58c9b69ce39d859',
// };

//G test
// var firebaseConfig = {
//   apiKey: 'AIzaSyBgcr7NOMY6ndAlv06I1ZnsJp9rQeNlDcE',
//   authDomain: 'adios-test.firebaseapp.com',
//   projectId: 'adios-test',
//   storageBucket: 'adios-test.appspot.com',
//   messagingSenderId: '728739653850',
//   appId: '1:728739653850:web:ef1d5a3a027eced15f5de3',
// };

// Initialize Firebase
var fireDb = firebase.initializeApp(firebaseConfig);
export default fireDb.database().ref();
