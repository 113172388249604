import React, { useState, useEffect } from 'react';
import PlayerForm from './PlayerForm';
import firebaseDb from '../firebase';
import Home from './Home';

const Players = () => {
  var [playerObjects, setPlayerObjects] = useState({});
  var [currentId, setCurrentId] = useState('');
  useEffect(() => {
    firebaseDb.child('players').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        setPlayerObjects({ ...snapshot.val() });
      } else {
        setPlayerObjects({});
      }
    });
  }, []);
  const addOrEdit = (obj) => {
    if (currentId) {
      firebaseDb.child(`players/${currentId}`).set(obj, (err) => {
        if (err) {
          console.log(err);
        } else {
          setCurrentId('');
        }
      });
    } else {
      firebaseDb.child('players').push(obj, (err) => {
        if (err) {
          console.log(err);
        } else {
          setCurrentId('');
        }
      });
    }
  };
  const onDelete = (key) => {
    if (window.confirm('Are you sure to delete this player')) {
      firebaseDb.child(`players/${key}`).remove((err) => {
        if (err) {
          console.log(err);
        } else {
          setCurrentId('');
        }
      });
    }
  };
  //console.log(playerObjects);
  return (
    <>
      <Home>
        <div className="holder">
          <h5 className="m-t-15">Add Player</h5>
          <div className="row">
            <div className="col-xs-12">
              <PlayerForm {...{ addOrEdit, currentId, playerObjects }} />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 m-t-30">
              {Object.keys(playerObjects).length > 0 && (
                <h5>List of Players</h5>
              )}
            </div>
          </div>
          {Object.keys(playerObjects).length > 0 && (
            <div className="row">
              <div className=" col-xs-12">
                <table className="table table-stripped">
                  <thead className="thead-light">
                    <tr>
                      <th>Full Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(playerObjects).map((id) => {
                      return (
                        <tr key={id}>
                          <td>{playerObjects[id].fullName}</td>
                          <td>
                            <a
                              className="btn text-primary"
                              onClick={() => {
                                setCurrentId(id);
                              }}
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </a>
                            <a
                              className="btn text-danger"
                              onClick={() => {
                                onDelete(id);
                              }}
                            >
                              <i className="far fa-trash-alt"></i>
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </Home>
    </>
  );
};

export default Players;
