import React, { useState, useEffect } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';

const TeamForm = (props) => {
  console.log(props);
  const initialTeamName = '';
  const initialPlayers = [];
  var [teamName, setTeamName] = useState(initialTeamName);
  var [playerList, setPlayerList] = useState([]);
  var [players, setPlayers] = useState(initialPlayers);
  useEffect(() => {
    if (props.currentId == '') {
      setTeamName(initialTeamName);
      setPlayers(initialPlayers);
    } else {
      setTeamName(props.teamObjects[props.currentId].teamName);
      setPlayers(props.teamObjects[props.currentId].players);
    }
  }, [props.currentId, props.teamObjects]);
  useEffect(() => {
    if (props.playerObjects !== null) {
      let list = [];
      Object.keys(props.playerObjects).map((id) => {
        list.push({
          id,
          fullName: props.playerObjects[id].fullName,
        });
      });
      setPlayerList(list);
    } else {
      setPlayerList([]);
    }
  }, [props.playerObjects]);
  const handleInputChange = (e) => {
    var { value } = e.target;
    setTeamName(value);
  };
  const handleSelectChange = (selectedPlayer) => {
    setPlayers(selectedPlayer);
  };
  const onRemoveSelectedValue = (remainingPlayers) => {
    setPlayers(remainingPlayers);
  };
  const handleFormSubmit = (e) => {
    let values = {
      teamName,
      players,
    };
    e.preventDefault();
    props.addOrEdit(values);
  };
  return (
    <form autoComplete="off" onSubmit={handleFormSubmit}>
      <div className="mb-3">
        <input
          className="form-control"
          placeholder="Team Name"
          name="teamName"
          value={teamName}
          onChange={handleInputChange}
        />
      </div>
      <div className="mb-3">
        <label>Select players</label>
        <div className="form-floating">
          <Multiselect
            showCheckbox={true}
            selectionLimit={4}
            options={playerList} // Options to display in the dropdown
            selectedValues={players} // Preselected value to persist in dropdown
            onSelect={handleSelectChange} // Function will trigger on select event
            onRemove={onRemoveSelectedValue} // Function will trigger on remove event
            displayValue="fullName" // Property name to display in the dropdown options
            placeholder={'Start typing to search...'}
            closeOnSelect={false}
          />
        </div>
      </div>
      <input
        value={props.currentId ? 'Update' : 'Save'}
        type="submit"
        className="btn btn-primary btn-block"
      />
    </form>
  );
};

export default TeamForm;
