import React, { useState, useEffect } from 'react';
import Home from './Home';
import _ from 'lodash';
import firebaseDb from '../firebase';
import { useParams, Link } from 'react-router-dom';

const MatchBoardForm = () => {
  const initialFieldValues = {
    values: {},
  };
  const _initialFieldValues = {
    _values: {},
  };
  const initialBetValue = 0;
  const _initialBetValue = 0;
  var [values, setValues] = useState(initialFieldValues);
  var [_values, set_Values] = useState(_initialFieldValues);
  var [bet, setBet] = useState(initialBetValue);
  var [_bet, set_Bet] = useState(_initialBetValue);
  let { matchId, playerId } = useParams();
  var [playerObjects, setPlayerObjects] = useState({});
  var [matchObjects, setMatchObject] = useState({});
  var [scoreCardObjects, setScoreCardObject] = useState({});
  useEffect(() => {
    firebaseDb.child('players').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        setPlayerObjects({ ...snapshot.val() });
      } else {
        setPlayerObjects({});
      }
    });
    firebaseDb.child('matches').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        setMatchObject({ ...snapshot.val() });
      } else {
        setMatchObject({});
      }
    });
    firebaseDb.child('scorecard').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        setScoreCardObject({ ...snapshot.val() });
      } else {
        setScoreCardObject({});
      }
    });
  }, []);
  useEffect(() => {
    let match = matchObjects[matchId];
    if (match) {
      let player1 = match[`player${playerId}`];
      let otherId = parseInt(playerId) === 1 ? 2 : 1;
      let player2 = match[`player${otherId}`];
      Object.keys(scoreCardObjects).map((id) => {
        if (scoreCardObjects[id].matchId === matchId) {
          if (scoreCardObjects[id].playerId === player1) {
            setValues(scoreCardObjects[id].score || []);
            setBet(scoreCardObjects[id].money || 0);
          }
          if (scoreCardObjects[id].playerId === player2) {
            set_Values(scoreCardObjects[id].score || []);
            set_Bet(scoreCardObjects[id].money || 0);
          }
        }
      });
    }
  }, [scoreCardObjects]);
  let match, player1, player2;
  if (matchObjects) {
    match = matchObjects[matchId];
  }
  if (match) {
    player1 = match[`player${playerId}`];
    let otherId = parseInt(playerId) === 1 ? 2 : 1;
    player2 = match[`player${otherId}`];
  }
  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const _handleInputChange = (e) => {
    var { name, value } = e.target;
    set_Values({
      ..._values,
      [name]: value,
    });
  };
  const handleBetInputChange = (e) => {
    var { name, value } = e.target;
    setBet(value);
  };
  const _handleBetInputChange = (e) => {
    var { name, value } = e.target;
    set_Bet(value);
  };
  const handleBetSubmit = (e) => {
    e.preventDefault();
    let obj = {};
    let update = false;
    let currentId;
    Object.keys(scoreCardObjects).map((id) => {
      if (scoreCardObjects[id].matchId === matchId) {
        if (scoreCardObjects[id].playerId === player1) {
          update = true;
          currentId = id;
        }
      }
    });
    if (update) {
      obj = { ...scoreCardObjects[currentId], money: bet };
    } else {
      obj = {
        matchId: matchId,
        playerId: player1,
        money: bet,
      };
    }
    if (update) {
      firebaseDb.child(`scorecard/${currentId}`).set(obj, (err) => {
        if (err) {
          console.log(err);
        }
      });
    } else {
      firebaseDb.child('scorecard').push(obj, (err) => {
        if (err) {
          console.log(err);
        }
      });
    }
  };
  const _handleBetSubmit = (e) => {
    e.preventDefault();
    let obj = {};
    let update = false;
    let currentId;
    Object.keys(scoreCardObjects).map((id) => {
      if (scoreCardObjects[id].matchId === matchId) {
        if (scoreCardObjects[id].playerId === player2) {
          update = true;
          currentId = id;
        }
      }
    });
    if (update) {
      obj = { ...scoreCardObjects[currentId], money: _bet };
    } else {
      obj = {
        matchId: matchId,
        playerId: player2,
        money: _bet,
      };
    }
    if (update) {
      firebaseDb.child(`scorecard/${currentId}`).set(obj, (err) => {
        if (err) {
          console.log(err);
        }
      });
    } else {
      firebaseDb.child('scorecard').push(obj, (err) => {
        if (err) {
          console.log(err);
        }
      });
    }
  };
  const updateMatchWinner = (player) => {
    let obj = { ...matchObjects[matchId], winner: player };
    firebaseDb.child(`matches/${matchId}`).set(obj, (err) => {
      if (err) {
        console.log(err);
      }
    });
  };
  const findMatchWinner = (value1, value2, p1, p2) => {
    let mappedValues = _.map(value2, (val) => {
      return parseInt(val);
    });
    let _mappedValues = [];
    Object.keys(value1).map((id) => {
      _mappedValues.push(parseInt(value1[id]));
    });
    if (_.max(mappedValues) === _.max(_mappedValues)) {
      alert('The highest scores are equal');
    } else if (_.max(mappedValues) > _.max(_mappedValues)) {
      if (_.max(mappedValues) !== undefined) {
        updateMatchWinner(p2);
      } else {
        updateMatchWinner(p1);
      }
    } else {
      if (_.max(_mappedValues) !== undefined) {
        updateMatchWinner(p1);
      } else {
        updateMatchWinner(p2);
      }
    }
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isFinalStarted() && isQualifierMatch()) {
      alert("Final match started; can't update the Qualifier match score!");
      return false;
    }
    let obj = {};
    let update = false;
    let currentId = '';
    Object.keys(scoreCardObjects).map((id) => {
      if (scoreCardObjects[id].matchId === matchId) {
        if (scoreCardObjects[id].playerId === player1) {
          update = true;
          currentId = id;
        }
      }
    });
    if (update) {
      obj = { ...scoreCardObjects[currentId], score: values };
    } else {
      obj = {
        matchId: matchId,
        playerId: player1,
        score: values,
      };
    }
    if (update) {
      firebaseDb.child(`scorecard/${currentId}`).set(obj, (err) => {
        if (err) {
          console.log(err);
        }
      });
    } else {
      firebaseDb.child('scorecard').push(obj, (err) => {
        if (err) {
          console.log(err);
        }
      });
    }
    if (_.size(values) === 6 && _.size(_values) === 6) {
      let matchTypes = [],
        matchKeys = [];
      Object.keys(matchObjects).map((id) => {
        matchTypes.push(matchObjects[id].matchType);
        matchKeys.push(id);
      });
      if (!isFinalStarted()) {
        Object.keys(matchObjects).map((id) => {
          if (matchObjects[_.last(matchKeys)].matchType === 'Final') {
            firebaseDb.child(`matches/${_.last(matchKeys)}`).remove((err) => {
              if (err) {
                console.log(err);
              }
            });
          }
        });
      }
      findMatchWinner(values, _values, player1, player2);
    }
  };
  const _handleFormSubmit = (e) => {
    e.preventDefault();
    if (isFinalStarted() && isQualifierMatch()) {
      alert("Final match started; can't update the Qualifier match score!");
      return false;
    }
    let obj = {};
    let update = false;
    let currentId = '';
    Object.keys(scoreCardObjects).map((id) => {
      if (scoreCardObjects[id].matchId === matchId) {
        if (scoreCardObjects[id].playerId === player2) {
          update = true;
          currentId = id;
        }
      }
    });
    if (update) {
      obj = { ...scoreCardObjects[currentId], score: _values };
    } else {
      obj = {
        matchId: matchId,
        playerId: player2,
        score: _values,
      };
    }
    if (update) {
      firebaseDb.child(`scorecard/${currentId}`).set(obj, (err) => {
        if (err) {
          console.log(err);
        }
      });
    } else {
      firebaseDb.child('scorecard').push(obj, (err) => {
        if (err) {
          console.log(err);
        }
      });
    }
    if (_.size(values) === 6 && _.size(_values) === 6) {
      let matchTypes = [],
        matchKeys = [];
      Object.keys(matchObjects).map((id) => {
        matchTypes.push(matchObjects[id].matchType);
        matchKeys.push(id);
      });
      Object.keys(matchObjects).map((id) => {
        matchTypes.push(matchObjects[id].matchType);
      });
      if (!isFinalStarted()) {
        Object.keys(matchObjects).map((id) => {
          if (matchObjects[_.last(matchKeys)].matchType === 'Final') {
            firebaseDb.child(`matches/${_.last(matchKeys)}`).remove((err) => {
              if (err) {
                console.log(err);
              }
            });
          }
        });
      }
      findMatchWinner(_values, values, player2, player1);
    }
  };
  const isQualifierMatch = () => {
    let currentMatch = matchObjects[matchId];
    if (_.isEqual(currentMatch.matchType, 'Qualifier')) {
      return true;
    }
    return false;
  };
  const isFinalMatch = () => {
    let currentMatch = matchObjects[matchId];
    if (_.isEqual(currentMatch.matchType, 'Final')) {
      return true;
    }
    return false;
  };
  const isFinalStarted = () => {
    let finalKey = _.last(_.keys(matchObjects));
    let finalMatch = _.head(_.filter(matchObjects, { matchType: 'Final' }));
    let firstPlayer, secondPlayer, player1Scorecard, player2Scorecard;
    if (finalMatch) {
      firstPlayer = finalMatch.player1;
      secondPlayer = finalMatch.player2;
      player1Scorecard = _.filter(scoreCardObjects, {
        matchId: finalKey,
        playerId: firstPlayer,
      });
      player2Scorecard = _.filter(scoreCardObjects, {
        matchId: finalKey,
        playerId: secondPlayer,
      });
    }
    if (
      _.size(finalMatch) &&
      (_.size(player1Scorecard) || _.size(player2Scorecard))
    ) {
      return true;
    }
    return false;
  };
  return (
    <Home>
      <div className="card-holder">
        <Link className="back-link" to="/">
          Back
        </Link>
        <div className="player-card">
          <div className="player-card-header">
            <div className="player-card-header-left">
              <div className="player-card-header-image">
                {player1 && playerObjects[player1].fullName.charAt(0)}
              </div>
              <div className="player-card-header-name">
                {player1 && playerObjects[player1].fullName}
              </div>
            </div>
            <div className="player-card-header-right">
              <div className="player-card-header-right-label">Money bet</div>
              <div className="player-card-header-right-value">
                <form autoComplete="off" onSubmit={handleBetSubmit}>
                  <input
                    className="bet-input"
                    placeholder=""
                    name="bet"
                    value={bet}
                    onChange={handleBetInputChange}
                    onBlur={handleBetSubmit}
                  />
                </form>
              </div>
            </div>
          </div>
          <div className="player-card-score">
            <div className="player-card-score-item">
              <span>1.</span>
              <form autoComplete="off" onSubmit={handleFormSubmit}>
                <input
                  className={
                    values[0] >= _.max(_.map(values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="0"
                  value={values[0]}
                  onChange={handleInputChange}
                  onBlur={handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              <span>2.</span>
              <form autoComplete="off" onSubmit={handleFormSubmit}>
                <input
                  className={
                    values[1] >= _.max(_.map(values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="1"
                  value={values[1]}
                  onChange={handleInputChange}
                  onBlur={handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              <span>3.</span>
              <form autoComplete="off" onSubmit={handleFormSubmit}>
                <input
                  className={
                    values[2] >= _.max(_.map(values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="2"
                  value={values[2]}
                  onChange={handleInputChange}
                  onBlur={handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              <span>4.</span>
              <form autoComplete="off" onSubmit={handleFormSubmit}>
                <input
                  className={
                    values[3] >= _.max(_.map(values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="3"
                  value={values[3]}
                  onChange={handleInputChange}
                  onBlur={handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              <span>5.</span>
              <form autoComplete="off" onSubmit={handleFormSubmit}>
                <input
                  className={
                    values[4] >= _.max(_.map(values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="4"
                  value={values[4]}
                  onChange={handleInputChange}
                  onBlur={handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              <span>6.</span>
              <form autoComplete="off" onSubmit={handleFormSubmit}>
                <input
                  className={
                    values[5] >= _.max(_.map(values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="5"
                  value={values[5]}
                  onChange={handleInputChange}
                  onBlur={handleFormSubmit}
                />
              </form>
            </div>
          </div>
        </div>
        <div className="player-card">
          <div className="player-card-header">
            <div className="player-card-header-left">
              <div className="player-card-header-image">
                {player1 && playerObjects[player2].fullName.charAt(0)}
              </div>
              <div className="player-card-header-name">
                {player1 && playerObjects[player2].fullName}
              </div>
            </div>
            <div className="player-card-header-right">
              <div className="player-card-header-right-label">Money bet</div>
              <div className="player-card-header-right-value">
                <form autoComplete="off" onSubmit={_handleBetSubmit}>
                  <input
                    className="bet-input"
                    placeholder=""
                    name="_bet"
                    value={_bet}
                    onChange={_handleBetInputChange}
                    onBlur={_handleBetSubmit}
                  />
                </form>
              </div>
            </div>
          </div>
          <div className="player-card-score">
            <div className="player-card-score-item">
              <span>1.</span>
              <form autoComplete="off" onSubmit={_handleFormSubmit}>
                <input
                  className={
                    _values[0] >= _.max(_.map(_values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="0"
                  value={_values[0]}
                  onChange={_handleInputChange}
                  onBlur={_handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              <span>2.</span>
              <form autoComplete="off" onSubmit={_handleFormSubmit}>
                <input
                  className={
                    _values[1] >= _.max(_.map(_values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="1"
                  value={_values[1]}
                  onChange={_handleInputChange}
                  onBlur={_handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              <span>3.</span>
              <form autoComplete="off" onSubmit={_handleFormSubmit}>
                <input
                  className={
                    _values[2] >= _.max(_.map(_values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="2"
                  value={_values[2]}
                  onChange={_handleInputChange}
                  onBlur={_handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              <span>4.</span>
              <form autoComplete="off" onSubmit={_handleFormSubmit}>
                <input
                  className={
                    _values[3] >= _.max(_.map(_values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="3"
                  value={_values[3]}
                  onChange={_handleInputChange}
                  onBlur={_handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              <span>5.</span>
              <form autoComplete="off" onSubmit={_handleFormSubmit}>
                <input
                  className={
                    _values[4] >= _.max(_.map(_values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="4"
                  value={_values[4]}
                  onChange={_handleInputChange}
                  onBlur={_handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              <span>6.</span>
              <form autoComplete="off" onSubmit={_handleFormSubmit}>
                <input
                  className={
                    _values[5] >= _.max(_.map(_values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="5"
                  value={_values[5]}
                  onChange={_handleInputChange}
                  onBlur={_handleFormSubmit}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </Home>
  );
};

export default MatchBoardForm;
