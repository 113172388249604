import React from 'react';
import { Link } from 'react-router-dom';
import AdiosLogo from './../images/adios.png';
import wtLogo from './../images/wt-logo.png';
import sponsor1 from './../images/sponsor1.png';
import sponsor2 from './../images/sponsor2.jpg';

const Home = ({ heading, children }) => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src={AdiosLogo} alt="app logo" className="logo-header" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  Pro Match Play
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/team-play">
                  Team Play
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/matches">
                  Pro Matches
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/team-play-matches">
                  Team Play Matches
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/teams">
                  Teams
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/players">
                  Players
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container home-wrapper">
        <div className="container text-center m-b-30">
          <img src={AdiosLogo} alt="app logo" className="logo" />
          <h4>Adios Long Drive</h4>
          <h4>Showcase {heading ? heading : 'Pro Match Play'}</h4>
        </div>
        {children}
        <div className="sponsor">
          <h4>Sponsors</h4>
          <div className="sponsor-wrapper">
            <div
              className="sponsor-logo"
              style={{
                backgroundImage: `url("${sponsor1}")`,
              }}
            ></div>
            <div
              className="sponsor-logo"
              style={{
                backgroundImage: `url("${sponsor2}")`,
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className="footer">
        <img src={wtLogo} alt="winningticket logo" className="footer-logo" />
      </div>
    </>
  );
};

export default Home;
