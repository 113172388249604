import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import MatchForm from './MatchForm';
import firebaseDb from '../firebase';
import Home from './Home';

const Matches = () => {
  const matchTypes = [
    {
      id: 'Qualifier',
      name: 'Qualifier',
    },
    {
      id: 'Final',
      name: 'Final',
    },
  ];
  var [playerObjects, setPlayerObjects] = useState({});
  var [matchObjects, setMatchObject] = useState({});
  var [currentId, setCurrentId] = useState('');
  useEffect(() => {
    firebaseDb.child('players').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        setPlayerObjects({ ...snapshot.val() });
      } else {
        setPlayerObjects({});
      }
    });
  }, []);
  useEffect(() => {
    firebaseDb.child('matches').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        setMatchObject({ ...snapshot.val() });
      } else {
        setMatchObject({});
      }
    });
  }, []);
  const addOrEdit = (obj) => {
    if (currentId) {
      firebaseDb.child(`matches/${currentId}`).set(obj, (err) => {
        if (err) {
          console.log(err);
        } else {
          setCurrentId('');
        }
      });
    } else {
      firebaseDb.child('matches').push(obj, (err) => {
        if (err) {
          console.log(err);
        } else {
          setCurrentId('');
        }
      });
    }
  };
  const onDelete = (key) => {
    if (window.confirm('Are you sure to delete this match')) {
      firebaseDb.child(`matches/${key}`).remove((err) => {
        if (err) {
          console.log(err);
        } else {
          setCurrentId('');
        }
      });
    }
  };
  return (
    <>
      <Home>
        <div className="holder">
          <h5 className="m-t-15">Add Match</h5>
          <div className="row">
            <div className="col-xs-12">
              <MatchForm
                {...{
                  addOrEdit,
                  currentId,
                  playerObjects,
                  matchObjects,
                  matchTypes,
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 m-t-30">
              {Object.keys(matchObjects).length > 0 && <h5>List of Matches</h5>}
            </div>
          </div>
          {Object.keys(matchObjects).length > 0 && (
            <div className="row">
              <div className=" col-xs-12">
                <table className="table table-stripped">
                  <thead className="thead-light">
                    <tr>
                      <th>Player 1</th>
                      <th>Player 2</th>
                      <th>Match Type</th>
                      <th>Winner</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(matchObjects).map((id) => {
                      return (
                        <tr key={id}>
                          <td>
                            {
                              playerObjects[`${matchObjects[id].player1}`]
                                .fullName
                            }
                          </td>
                          <td>
                            {
                              playerObjects[`${matchObjects[id].player2}`]
                                .fullName
                            }
                          </td>
                          <td>{matchObjects[id].matchType}</td>
                          <td>
                            {matchObjects[id].winner
                              ? playerObjects[`${matchObjects[id].winner}`]
                                  .fullName
                              : '-'}
                          </td>
                          <td>
                            <a
                              className="btn text-primary"
                              onClick={() => {
                                setCurrentId(id);
                              }}
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </a>
                            <a
                              className="btn text-danger"
                              onClick={() => {
                                onDelete(id);
                              }}
                            >
                              <i className="far fa-trash-alt"></i>
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </Home>
    </>
  );
};

export default Matches;
