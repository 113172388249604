import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import firebaseDb from '../firebase';

const TeamPlayMatchBoardForm = (props) => {
  const { matchId, teamId, playerId } = props;
  const initialFieldValues = [];
  var [values, setValues] = useState(initialFieldValues);
  var [scoreCardObjects, setScoreCardObject] = useState({});
  useEffect(() => {
    firebaseDb.child('teamPlayScorecard').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        setScoreCardObject({ ...snapshot.val() });
      } else {
        setScoreCardObject({});
      }
    });
  }, []);
  useEffect(() => {
    let found = false;
    if (matchId) {
      Object.keys(scoreCardObjects).map((id) => {
        if (
          scoreCardObjects[id].matchId === matchId &&
          scoreCardObjects[id].teamId === teamId.id &&
          scoreCardObjects[id].playerId === playerId.id
        ) {
          setValues(scoreCardObjects[id].score || []);
          found = true;
        }
      });
      if (!found) {
        setValues([]);
      }
    }
  }, [scoreCardObjects, matchId, teamId, playerId]);

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    let obj = {};
    let update = false;
    let currentId = '';
    Object.keys(scoreCardObjects).map((id) => {
      if (scoreCardObjects[id].matchId === matchId) {
        if (scoreCardObjects[id].teamId === teamId.id) {
          if (scoreCardObjects[id].playerId === playerId.id) {
            update = true;
            currentId = id;
          }
        }
      }
    });
    if (update) {
      obj = { ...scoreCardObjects[currentId], score: values };
    } else {
      obj = {
        matchId: matchId,
        playerId: playerId.id,
        teamId: teamId.id,
        score: values,
      };
    }
    if (update) {
      firebaseDb.child(`teamPlayScorecard/${currentId}`).set(obj, (err) => {
        if (err) {
          console.log(err);
        }
      });
    } else {
      firebaseDb.child('teamPlayScorecard').push(obj, (err) => {
        if (err) {
          console.log(err);
        }
      });
    }
  };
  return (
    <div className="card-holder">
      <div className="player-card">
        <div className="player-card-header">
          <div className="player-card-header-left">
            <div className="player-card-header-image">
              {playerId && playerId.fullName.charAt(0)}
            </div>
            <div className="player-card-header-name">
              {playerId && playerId.fullName}
              <div className="player-team-name">
                {teamId && teamId.teamName}
              </div>
            </div>
          </div>
        </div>
        <div className="player-card-score">
          <div className="player-card-score-item">
            <span>1.</span>
            <form autoComplete="off" onSubmit={handleFormSubmit}>
              <input
                className={
                  values[0] >= _.max(_.map(values, _.parseInt))
                    ? 'score-input highest'
                    : 'score-input'
                }
                placeholder=""
                name="0"
                value={values[0] || ''}
                onChange={handleInputChange}
                onBlur={handleFormSubmit}
              />
            </form>
          </div>
          <div className="player-card-score-item">
            <span>2.</span>
            <form autoComplete="off" onSubmit={handleFormSubmit}>
              <input
                className={
                  values[1] >= _.max(_.map(values, _.parseInt))
                    ? 'score-input highest'
                    : 'score-input'
                }
                placeholder=""
                name="1"
                value={values[1] || ''}
                onChange={handleInputChange}
                onBlur={handleFormSubmit}
              />
            </form>
          </div>
          <div className="player-card-score-item">
            <span>3.</span>
            <form autoComplete="off" onSubmit={handleFormSubmit}>
              <input
                className={
                  values[2] >= _.max(_.map(values, _.parseInt))
                    ? 'score-input highest'
                    : 'score-input'
                }
                placeholder=""
                name="2"
                value={values[2] || ''}
                onChange={handleInputChange}
                onBlur={handleFormSubmit}
              />
            </form>
          </div>
          <div className="player-card-score-item">
            <span>4.</span>
            <form autoComplete="off" onSubmit={handleFormSubmit}>
              <input
                className={
                  values[3] >= _.max(_.map(values, _.parseInt))
                    ? 'score-input highest'
                    : 'score-input'
                }
                placeholder=""
                name="3"
                value={values[3] || ''}
                onChange={handleInputChange}
                onBlur={handleFormSubmit}
              />
            </form>
          </div>
          <div className="player-card-score-item">
            <span>5.</span>
            <form autoComplete="off" onSubmit={handleFormSubmit}>
              <input
                className={
                  values[4] >= _.max(_.map(values, _.parseInt))
                    ? 'score-input highest'
                    : 'score-input'
                }
                placeholder=""
                name="4"
                value={values[4] || ''}
                onChange={handleInputChange}
                onBlur={handleFormSubmit}
              />
            </form>
          </div>
          <div className="player-card-score-item">
            <span>6.</span>
            <form autoComplete="off" onSubmit={handleFormSubmit}>
              <input
                className={
                  values[5] >= _.max(_.map(values, _.parseInt))
                    ? 'score-input highest'
                    : 'score-input'
                }
                placeholder=""
                name="5"
                value={values[5] || ''}
                onChange={handleInputChange}
                onBlur={handleFormSubmit}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamPlayMatchBoardForm;
