import React, { useState, useEffect } from 'react';
import Home from './Home';
import _ from 'lodash';
import firebaseDb from '../firebase';
import { Link } from 'react-router-dom';
const MatchBoard = () => {
  var [playerObjects, setPlayerObjects] = useState({});
  var [matchObjects, setMatchObject] = useState({});
  useEffect(() => {
    firebaseDb.child('players').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        setPlayerObjects({ ...snapshot.val() });
      } else {
        setPlayerObjects({});
      }
    });
    firebaseDb.child('matches').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        setMatchObject({ ...snapshot.val() });
      } else {
        setMatchObject({});
      }
    });
  }, []);
  let qualifierMatchObjects = [],
    finalMatchObject = [];
  let count = 1;
  let finalObj = {};
  let matchTypes = [];
  Object.keys(matchObjects).map((id) => {
    matchTypes.push(matchObjects[id].matchType);
  });
  if (Object.keys(matchObjects).length === 4) {
    let matchKeys = [];
    Object.keys(matchObjects).map((id) => {
      matchKeys.push(id);
    });
    if (matchObjects[_.last(matchKeys)].matchType === 'Final') {
      firebaseDb.child(`matches/${_.last(matchKeys)}`).remove((err) => {
        if (err) {
          console.log(err);
        }
      });
    }
  }

  if (Object.keys(matchObjects).length === 2) {
    Object.keys(matchObjects).map((id) => {
      if (_.last(matchTypes) !== 'Final') {
        if (matchObjects[id].matchType === 'Qualifier') {
          if (matchObjects[id].hasOwnProperty('winner')) {
            finalObj[`player${count}`] = matchObjects[id].winner;
            count++;
          }
        }
      }
    });
  }
  if (
    finalObj.hasOwnProperty('player1') &&
    finalObj.hasOwnProperty('player2') &&
    _.last(matchTypes) !== 'Final'
  ) {
    finalObj['matchType'] = 'Final';
    firebaseDb.child('matches').push(finalObj, (err) => {
      finalObj = {};
      if (err) {
        console.log(err);
      }
    });
    firebaseDb.child('matches').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        setMatchObject({ ...snapshot.val() });
      }
    });
  }
  Object.keys(matchObjects).map((id) => {
    let obj = {};
    if (matchObjects[id].matchType === 'Qualifier') {
      obj.id = id;
      obj.player1 = matchObjects[id].player1;
      obj.player2 = matchObjects[id].player2;
      if (matchObjects[id].winner) {
        obj.winner = matchObjects[id].winner;
      }
      qualifierMatchObjects.push(obj);
    }
    if (matchObjects[id].matchType === 'Final') {
      obj.id = id;
      obj.player1 = matchObjects[id].player1;
      obj.player2 = matchObjects[id].player2;
      if (matchObjects[id].winner) {
        obj.winner = matchObjects[id].winner;
      }
      finalMatchObject.push(obj);
    }
  });
  if (finalMatchObject.length) {
    finalMatchObject = [finalMatchObject[0]];
  }

  return (
    <Home>
      <div className="match-board">
        {qualifierMatchObjects.length <= 0 && <div>No matches</div>}
        <div className="match-board-section">
          {_.map(qualifierMatchObjects, (item) => {
            return (
              <div className="player-holder" key={item.id}>
                <Link
                  to={`/scorecard/${item.id}/1`}
                  className={
                    item.winner === item.player1 ? 'player winner' : 'player'
                  }
                >
                  <div className="player-image">
                    {playerObjects[item.player1].fullName.charAt(0)}
                  </div>
                  <div className="player-name">
                    {playerObjects[item.player1].fullName}
                  </div>
                </Link>
                <Link
                  to={`/scorecard/${item.id}/2`}
                  className={
                    item.winner === item.player2 ? 'player winner' : 'player'
                  }
                >
                  <div className="player-image">
                    {playerObjects[item.player2].fullName.charAt(0)}
                  </div>
                  <div className="player-name">
                    {playerObjects[item.player2].fullName}
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
        {qualifierMatchObjects.length > 1 && (
          <div className="match-arrow"></div>
        )}

        {qualifierMatchObjects.length > 1 && finalMatchObject.length === 0 && (
          <div className="match-board-section">
            <div className="player-holder">
              <div className="player">
                <div className="player-image"></div>
                <div className="player-name"></div>
              </div>
              <div className="player">
                <div className="player-image"></div>
                <div className="player-name"></div>
              </div>
            </div>
          </div>
        )}
        <div className="match-board-section">
          {_.map(finalMatchObject, (item) => {
            return (
              <div className="player-holder" key={item.id}>
                <Link
                  to={`/scorecard/${item.id}/1`}
                  className={
                    item.winner === item.player1 ? 'player winner' : 'player'
                  }
                >
                  <div className="player-image">
                    {playerObjects[item.player1].fullName.charAt(0)}
                  </div>
                  <div className="player-name">
                    {playerObjects[item.player1].fullName}
                  </div>
                </Link>
                <Link
                  to={`/scorecard/${item.id}/2`}
                  className={
                    item.winner === item.player2 ? 'player winner' : 'player'
                  }
                >
                  <div className="player-image">
                    {playerObjects[item.player2].fullName.charAt(0)}
                  </div>
                  <div className="player-name">
                    {playerObjects[item.player2].fullName}
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </Home>
  );
};

export default MatchBoard;
